table.tb-daftar-dinas {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.tb-daftar-dinas td,
table.tb-daftar-dinas th {
  border: 1px solid #aaaaaa;
  padding: 1px 2px;
  font-size: 10px;
}
table.tb-daftar-dinas tbody td {
  font-size: 12px;
}
table.tb-daftar-dinas tr:nth-child(even) {
  background: #d0e4f5;
}
table.tb-daftar-dinas thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(
    top,
    #5592bb 0%,
    #327cad 66%,
    #1c6ea4 100%
  );
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.tb-daftar-dinas thead th {
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  border-left: 0px solid #d0e4f5;
}
table.tb-daftar-dinas thead th:first-child {
  border-left: none;
}

table.tb-daftar-dinas tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(
    top,
    #dcebf7 0%,
    #d4e6f6 66%,
    #d0e4f5 100%
  );
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}
table.tb-daftar-dinas tfoot td {
  font-size: 14px;
}
table.tb-daftar-dinas tfoot .links {
  text-align: right;
}
table.tb-daftar-dinas tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
table.tb-daftar-dinas tbody tr:hover {
  background-color: #acd0e7;
  color: #333;
}
